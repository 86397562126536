import React, { FC, useContext, useEffect } from "react";
import { Heading, IconButton, Stack } from "@chakra-ui/react";
import { LayoutContext } from "../../components/layout";
import { motion } from "framer-motion";
import { graphql, Link, PageProps } from "gatsby";
import { ProjectTemplateQuery } from "../../../graphql-types";
import { ArrowBackIcon } from "@chakra-ui/icons";
import theme from "../../@chakra-ui/gatsby-plugin/theme";

export const query = graphql`
  query ProjectTemplate($id: String!) {
    wpProject(id: { eq: $id }) {
      id
      title
    }
  }
`;

const ProjectTemplate: FC<PageProps<ProjectTemplateQuery>> = props => {
  const { data } = props;
  const project = data.wpProject;

  const { mapMachine } = useContext(LayoutContext);
  const [, mapSend] = mapMachine as NonNullable<typeof mapMachine>;

  useEffect(() => {
    // mapSend({ type: "GO_TO_PROJECT", projectId: "..." }); -- type error, why?
    mapSend("GO_TO_PROJECT", { projectId: project?.id });

    dataLayer.push({
      event: "someRandomEvent",
    });
  }, [mapSend]);

  return (
    <motion.div
      key={props.path}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Link to="/">
        <Stack
          direction="row"
          spacing={6}
          position="fixed"
          bottom={{ base: 12, md: 16 }}
          left={{ base: 8, md: 16 }}
          align="center"
        >
          <IconButton
            size="lg"
            as="div"
            aria-label="terug naar overzicht"
            icon={<ArrowBackIcon />}
            color="black"
          />

          <Heading
            textStyle="korolevCompressed"
            fontSize={{ base: "4xl", md: "5xl", xl: "6xl" }}
            pointerEvents="all"
            color="white"
            position="relative"
            _after={{
              content: "''",
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: -3,
              borderBottomWidth: 6,
              transition: "transform 0.2s ease-in-out",
              transform: "scale(0, 1)",
              transformOrigin: "bottom left",
            }}
            _hover={{
              _after: { transform: "scale(1, 1)" },
            }}
          >
            <motion.span
              animate={{
                backgroundPosition: "0%",
              }}
              whileHover={{
                backgroundPosition: ["0%", "-100%", "-200%"],
              }}
              transition={{ duration: 0.6 }}
              style={{
                backgroundImage: `linear-gradient(to right, white 0%, white 50%, ${theme.colors.gold} 50%)`,
                backgroundSize: "200%",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              {project?.title}
            </motion.span>
          </Heading>
        </Stack>
      </Link>
    </motion.div>
  );
};

export default ProjectTemplate;
